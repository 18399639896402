/*Mobile*/
@media (max-width: 1024px) {
    .products {
        background-color: rgb(233, 233, 233);
    }

    .productsContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .productsHeader {
        background-color: rgb(233, 233, 233);
        width: 100%;
        padding-top: 3%;
        padding-bottom: 3%;
        border-bottom: solid 1px black;
    }

    .productsBanner {
        display: none;
    }

    .productsTitle {
        color: rgb(0, 112, 176);
        text-align: center;
    }

    .productsLines {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-top: 2%;
        margin-bottom: 10%;
    }

    .productsLineBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .productsLinks {
        text-decoration: none;
        text-align: center;
    }

    .productsLineImg {
        text-align: center;
        display: none;
    }

    .productsLineTitle {
        color: rgb(0, 112, 176);
        text-align: center;
        font-size: 150%;
    }

    .productsList {
        text-align: center;
    }

    .productsListLink {
        text-align: center;
        text-decoration: none;
    }

    .productsListItem {
        text-align: center;
        list-style-type: none;
        text-decoration: none;
        color: rgb(0, 112, 176);
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .productsContent {
        width: 100%;
    }

    .productsHeader {
        width: 100%;
    }

    .productsBanner {
        width: 100%;
        height: 140px;
        object-fit: cover;
    }

    .productsTitle {
        color: white;
        margin: -8% 0% 0% 0%;
        text-align: center;
        font-size: 300%;
    }

    .productsLines {
        margin: 6% 0% 6% 0%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }

    .productsLinks {
        text-decoration: none;
    }

    .productsLineBox {
        background-color: rgb(237, 245, 252);
        border-radius: 20px;
        width: 45%;
        height: 620px;
        margin: 1%;
        overflow: hidden;
        border: 1px solid rgb(237, 245, 252);
    }

    .productsLineBox:hover {
        cursor: pointer;
        background-color: rgba(255, 0, 68, 0.1);
        transition: all 1s;
    }

    .productsLineTitle {
        color: rgb(0, 112, 176);
        font-size: 200%;
        text-align: center;
    }

    .productsLineTitle:hover {
        transition: all 1s;
        color: rgb(255, 0, 68);
    }

    .productsLineImg {
        object-fit: cover;
        width: 100%;
        height: 240px;
    }

    .productsList {
        list-style-type: none;
    }

    .productsListLink {
        text-decoration: none;
    }

    .productsListItem {
        font-size: 115%;
        text-align: center;
        color: rgb(35, 35, 37);
        margin: 0%;
    }
}