/*Mobile*/
@media (max-width: 1024px) {
    .rowA {
        display: none;
    }

    .header {
        width: 100%
    }

    .mobileHeader {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        background-color: white;
    }

    .logoMobile {
        width: 100%;
    }

    .linkLogoMobile {
        text-decoration: none;
    }

    .logoTextMobile {
        width: 100%;
        font-family: Perpetua;
        color: rgb(255, 0, 68);
        text-align: center;
        font-size: 240%;
    }

    .navbarMobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(35, 35, 37);
    }

    .linksMobileA {
        text-decoration: none;
    }

    .navbarMobileText {
        color: white;
        font-size: 120%;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .mobileHeader {
        display: none;
    }

    .header {
        display: flex;
        flex-direction: column;
        background-color: rgb(233, 233, 233)
    }

    .header.fixed {
        position: fixed;
        top: 0;
        background-color: rgb(233, 233, 233);
        width: 100%;
        height: 11%;
        font-size: 90%;
        transition: all 1s;
    }

    .rowA {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 90px;
    }

    .logo {
        width: 100%;
        height: 100%;
    }

    .linkLogo {
        text-decoration: none;
    }

    .logoText {
        color: rgb(255, 0, 68);
        font-family: Perpetua;
        font-size: 245%;
        font-weight: 600;
        margin-left: 7%;
    }

    .logoText:hover {
        color: rgba(255, 0, 68, 0.5);
    }

    .navbarA {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .navbarLinks {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .linksA {
        text-decoration: none;
    }

    .navbarAText {
        color: rgb(0, 122, 176);
        font-size: 120%;
    }

    .navbarAText:hover {
        color: rgba(0, 122, 176, 0.8);
    }

    .navbarProducts {
        display: none;
    }
}