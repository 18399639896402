/*Desktop*/
@media (min-width: 1042px) {
    .detail {
        width: 100%;
    }

    .detailContent {
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }

    .detailImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 5%;
        width: 40%;
        height: 100%;
    }

    .detailImg {
        object-fit: contain;
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .detailImg2 {
        object-fit: contain;
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 10% 0% 0% 0%;
        border-radius: 8px;
    }

    .detailLink {
        text-decoration: none;
    }

    .detailFolleto {
        background-color: rgb(0, 112, 176);
        color: white;
        text-align: center;
        border-radius: 45px;
        padding: 8px 35px 8px 35px;
        margin: 15% 0% 0% 0%;
        font-size: 160%;
    }

    .detailFolleto:hover {
        background-color: rgb(255, 0, 68);
        transition: all 0.5s;
    }

    .detailSide {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .detailContainer {
        padding: 1% 5% 1% 5%;
    }

    .detailTitle {
        color: rgba(0, 112, 176, 0.4);
        font-size: 310%;
        margin: 2% 0% -5% 0%;
    }

    .detailSubtitle {
        color: rgb(0, 112, 176);
        font-size: 250%;
    }

    .detailSeparator {
        color: rgb(0, 112, 176);
        margin: -7% 0% 0% 0%;
        font-size: 120%;
        font-weight: 600;
    }

    .detailLine {
        text-decoration: none;
        color: grey;
        font-size: 110%;
        margin: -0% 0% 5% 0%;
    }

    .detailContainer2 {
        width: 100%;
    }

    .descShort {
        color: rgb(35, 35, 37);
        font-size: 180%;
        text-align: left;
        margin: -2% 0% 5% 0%;
    }

    .descriptionList {
        width: 85%;
        margin: 5% 1% 5% 5%;
        border: 0px solid black;
        border-radius: 45px;
        background-color: rgb(237, 245, 252);
        text-align: left;
        padding: 1%;
        font-size: 120%;
        line-height: 150%;
    }


    .detailDescriptionTitle {
        color: rgb(0, 112, 176);
        padding: 1%;
        text-align: center;
        font-size: 130%;
    }

    .detailFooter {
        width: 100%;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: rgb(0, 112, 176);
        margin: 0% 0% 3% 0%;
    }
    
    .detailFooterTitle {
        color: white;
        text-align: center;
    }

    .detailFolleto2 {
        background-color: rgb(255, 0, 68);
        color: white;
        text-align: center;
        border-radius: 45px;
        padding: 8px 35px 8px 35px;
        margin: 1% 35% 1% 35%;
        font-size: 160%;
    }

    .detailFolleto2:hover {
        background-color: white;
        color: rgb(255, 0, 68);
        transition: all 0.5s;
    }
}