/*Mobile*/
@media (max-width: 1024px) {
    .footer {
        background-color: rgb(35, 35, 37);
    }

    .footerContent {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .footerLinksContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 3% 0% 0% 0%;
    }

    .footerLinks {
        text-decoration: none;
    }

    .footerNavbarText {
        color: rgb(233, 233, 233);
        font-size: 125%;
    }

    .footerTrademark {
        color: white;
        text-align: center;
        margin: 5% 0% 5% 0%;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .footer {
        background-color: rgb(35, 35, 37);
        height: 230px;
    }

    .footerContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
    }

    .footerLinksContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 4% 2% -1% 2%;
    }

    .footerLinks {
        text-decoration: none;
        border-radius: 45px;
        padding-left: 7%;
        padding-right: 7%;
        font-size: 120%;
        background-color: rgb(0, 112, 176);
    }

    .footerLinks:hover {
        transition: all 0.5s;
        background-color: rgba(255, 0, 68, 1);
    }

    .footerNavbarText {
        color: white;
    }

    .footerTrademark {
        color: grey;
        text-align: center;
        margin: 7% 0% 0% 0%;
        font-size: 120%;
    }
}