/*Mobile*/
@media (max-width: 1024px) {
    .contact {
        width: 100%;
        height: 100%;
    }

    .contactContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(233, 233, 233, 0.3);
        margin: 2% 0% 4% 0%;
    }

    .contactTitle {
        color: rgb(0, 112, 176);
        font-size: 250%;
        text-align: center;
        margin: 5% 0% 3% 0%;
        padding: 0.5%;
        align-self: center;
    }

    .contactBox {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0% 0% 2% 0%;
        margin: 0% 8% 3% 8%;
    }

    .contactEmailBox {
        width: 100%;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .emailBox {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .contactEmail {
        color: rgb(0, 112, 176);
        font-size: 150%;
        text-align: center;
    }

    .contactEmail:hover {
        color: rgb(255, 0, 68);
        cursor: pointer;
    }

    .contactNumbers {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        margin: 0% 0%0%0%;
    }

    .contact1 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .contactSubtitle {
        color: rgb(0, 112, 176);
        text-align: center;
        font-size: 175%;
    }

    .contactNumber {
        text-align: center;
        font-size: 130%;
        margin-top: -3%;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .contact {
        width: 100%;
        height: 100%;
        background-image: url("../../../assets//img//banner//contactBanner.jpeg");
        background-position: center;
        background-size: cover;
    }

    .contactContent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(233, 233, 233, 0.3);
        margin: 2% 0% 4% 0%;
    }

    .contactTitle {
        background-color: rgb(237, 245, 252);
        color: rgb(0, 112, 176);
        font-size: 250%;
        text-align: center;
        margin: 5% 0% 3% 0%;
        padding: 0.5%;
        width: 50%;
        align-self: center;
        border-radius: 8px;
    }

    .contactBox {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0% 0% 2% 0%;
        margin: 0% 8% 3% 8%;
        border: 0px solid black;
        border-radius: 45px;
        background-color: rgb(237, 245, 252);
    }

    .contactEmailBox {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 2%;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .emailBox {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .contactEmail {
        color: rgb(0, 112, 176);
        font-size: 170%;
        text-align: center;
    }

    .contactEmail:hover {
        color: rgb(255, 0, 68);
        cursor: pointer;
    }

    .contactNumbers {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        margin: 0% 0%0%0%;
    }

    .contact1 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .contactSubtitle {
        color: rgb(0, 112, 176);
        text-align: center;
        font-size: 175%;
    }

    .contactNumber {
        text-align: center;
        font-size: 130%;
        margin-top: -3%;
    }

}