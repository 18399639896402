/*Desktop*/
@media (min-width: 1024px) {
    .line {
        width: 100%;
    }

    .lineContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .lineBanner {
        width: 100%;
        background-color: rgba(255, 0, 68, 0.2);
        ;
    }

    .lineTitle {
        text-align: center;
        color: rgb(35, 35, 37);
    }

    .lineProducts {
        width: 100%;
        padding: 1%;
    }

    .lineLink {
        text-decoration: none;
    }

    .lineProductsBox {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .lineProductTitle {
        text-align: center;
        color: rgb(0,112,176);
    }

    .lineProductImg {
        margin: auto;
        width: 10%;
    }

    .lineProductDescription {
        text-align: center;
        color: grey;
        padding: 0% 40% 0% 40%;
    }
}