@font-face {
  font-family: 'Roboto Condensed';
  src: url("../../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf") format("woff2"),
    url("../../fonts//Roboto_Condensed/RobotoCondensed-Light.ttf") format("woff");
  font-weight: normal;
  font-display: swap;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Condensed';
}
/* 
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Perpetua';
} */