/*Mobile*/
@media (max-width: 1024px) {
    .about {
        background-color: rgb(233, 233, 233);
    }

    .aboutContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .aboutBanner {
        margin: auto;
    }

    .aboutBannerImg {
        display: none;
    }

    .aboutTitle {
        color: red;
        font-size: 270%;
    }

    .aboutSubtitle {
        color: rgb(81, 79, 79);
        font-size: 150%;
        margin-left: 12%;
        margin-bottom: -8%;
    }

    .aboutBoxes {
        width: 100%;
    }

    .aboutBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4%;
    }

    .aboutBoxTitle {
        font-size: 170%;
        color: rgb(0, 112, 176);
    }

    .aboutBoxText {
        font-size: 130%;
        text-align: center;
    }

    .aboutCountriesContainer {
        width: 100%;
    }

    .aboutCountries {
        border-top: 1px solid gray;
    }

    .aboutCountriesTitle {
        text-align: center;
        color: rgb(0, 112, 176);
    }

    .aboutCountriesFullList {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .countryFlag1 {
        width: 15%;
    }

    .countryName {
        display: none;
    }

    .aboutCountriesList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .flagList1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 2%;
    }

    .countryFlag {
        width: 50%;
        margin: 4%;
    }

    .countryName {
        font-size: 120%;
        text-align: center;
    }

    .flagList2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 2%;
    }

    .flagList3 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 2%;
    }

    .aboutCountriesBanner {
        display: none;
    }

    .aboutCountriesProv {
        display: flex;
        flex-direction: column;
        margin-top: 8%;
        background-color: white;
    }

    .provTitle {    
        color: rgb(0, 112, 176);
        text-align: center;
    }

    .provList {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 85%;
        margin: auto;
    }

    .provLogo {
        width: 35%;
    }

    .provLogoM {
        width: 43%;
    }

    .aboutValues {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0% 0% 0% 0%;
        padding: 5%;
        background-color: rgba(255, 0, 68, 0.7);
        width: 100%;
    }

    .aboutValuesTitle {
        font-size: 200%;
        color: white;
        text-align: center;
    }

    .aboutValuesContent {
        color: white;
        font-size: 130%;
        text-align: center;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .about {
        width: 100%;
    }

    .aboutContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .aboutBanner {
        width: 100%;
    }

    .aboutBannerImg {
        display: none;
    }

    .aboutSubtitle {
        margin: 4% 0% -0.5% -18%;
        color: grey;
        font-size: 130%;
        text-align: center;
    }

    .aboutTitle {
        margin: 0% 0% 0% 0%;
        color: rgb(255, 0, 68);
        font-size: 350%;
        font-family: Perpetua;
        text-align: center;
    }

    .aboutBoxes {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background-color: rgb(237, 245, 252);
        margin: 3% 2% 2% 2%;
        padding: 2%;
        border-radius: 8px;
    }

    .aboutBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .aboutBoxTitle {
        text-align: center;
        color: rgb(0, 112, 176);
        font-size: 160%;
    }

    .aboutBoxText {
        text-align: center;
        padding: 1%;
        margin: 3% 10% 3% 10%;
        font-size: 115%;
    }

    .aboutCountriesContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 3% 0% 0% 0%;
        height: 500px;
        width: 100%;
    }

    .aboutCountries {
        width: 30%;
    }

    .aboutCountriesTitle {
        text-align: center;
        margin: 0% 0% 0% 0%;
    }

    .aboutCountriesFullList {
        width: 95%;
        display: flex;
        flex-direction: column;
        margin: 4% 0% 0% 0%;
    }

    .aboutCountriesList {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: 50%;
        width: 100%;
        margin: auto;
    }

    .flagList1 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap: nowrap;
    }

    .flagList2 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap: nowrap;
    }

    .flagList3 {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap: nowrap;
    }

    .countryFlag1 {
        width: 25%;
        margin: auto;
        padding: 2%;
    }

    .countryFlag {
        width: 65%;
        margin: auto;
        padding: 2%;
    }

    .countryName {
        font-size: 110%;
        text-align: center;
        margin-top: -2%;
    }

    .aboutCountriesBanner {
        width: 30%;
        height: 65%;
        border-radius: 8px;
        margin: 4% 0% 0% 0%;
    }

    .aboutCountriesProv {
        width: 30%;
    }

    .provTitle {
        text-align: center;
        margin: 0% 0% 10% 0%;
    }

    .provList {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 85%;
        margin: auto;
    }

    .provLogo {
        width: 35%;
    }

    .provLogoM {
        width: 43%;
    }

    .aboutValues {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: -2% 0% 0% 0%;
        padding: 5%;
        background-color: rgba(255, 0, 68, 0.7);
        width: 100%;
    }

    .aboutValuesTitle {
        font-size: 200%;
        color: white;
    }

    .aboutValuesContent {
        color: white;
        font-size: 130%;
    }
}