/*Mobile*/
@media (max-width: 1024px) {
    .productShowcase {
        display: flex;
        flex-direction: row;
    }
}

/*Desktop*/
@media (min-width: 1042px) {
    .productShowcase {
        display: flex;
        flex-direction: column;
    }

    .showcaseContainer {
        background-color: rgb(35,35,37);
        width: 100%;
    }

    .showcaseTitle {
        color: white;
        font-size: 220%;
        text-align: center;
        margin: 5% 0% 4% 0%;
    }

    .showcaseBoxContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .showcaseBox {
        background-color: rgb(233, 233, 233);
        width: 25%;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 45px;
        box-shadow: 0px 0px 5px rgb(191, 191, 198);
        margin: 0% 0% 5% 0%;
    }

    .showcaseBox:hover {
        background-color: rgb(237, 245, 252);
        transition: all 1s;
    }

    .showcaseLink {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 45px;
    }

    .showcaseImgContainer {
        background-color: white;
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 45px;
        border-bottom-left-radius: 0px;
    }

    .showcaseImg {
        object-fit: contain;
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 8px;
        padding: 1%;
    }

    .showcaseModel {
        text-align: center;
        color: rgb(0,112,176);
        font-size: 220%;
        margin: 4% 0% 0% 0%;
    }

    .showcaseModel:hover {
        color: rgba(255,0,68,0.7);
        transition: all 1s;
    }

    .showcaseBrand {
        text-align: center;
        font-size: 150%;
        color: rgba(0, 112, 176, 0.4);
        margin: 4% 0% 0% 0%;
    }

    .showcaseBrand:hover {
        color: rgb(0,112,176);
        transition: all 1s;
    }

    .showcaseLine {
        color: grey;
        margin: -2% 0% 5% 0%;
    }

    .showcaseDesc {
        color: rgb(35,35,37);
        font-size: 120%;
        padding: 0% 3% 0% 3%;
        text-align: center;
    }
}