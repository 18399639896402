/*Mobile*/
@media (max-width: 1024px) {
    .home {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .home {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}