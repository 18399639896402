/*Mobile*/
@media (max-width: 1024px) {
    .promo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .promoAContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }

    .promoCountries {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .titleA {
        color: rgb(0, 122, 176);
        font-size: 200%;
        text-align: center;
        margin: 4% 0% 2% 0%;
    }

    .promoLinkA {
        text-decoration: none;
        width: 100%;
    }

    .countriesContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .promoCountriesList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
        height: 200px;
        background-image: url('../../../assets//img//promo/cuadro.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .countryFlagP {
        width: 8%;
        margin: 1%;
    }

    .promoProvList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        width: 100%;
        margin-bottom: 2%;
    }

    .provLogoA {
        width: auto;
        max-height: 50px;
    }

    .provLogoB {
        width: auto;
        max-height: 90px;
    }

    .provLogoC {
        width: auto;
        max-height: 30px;
    }

    .provLogoD {
        width: auto;
        max-height: 115px;
    }

    .provLogoE {
        width: auto;
        max-height: 80px;
    }

    .provLogoF {
        width: auto;
        max-height: 39px;
    }

    .provLogoG {
        width: auto;
        max-height: 120px;
    }

    .promoBContainer {
        background-color: rgb(0, 112, 176);
        width: 100%;
        overflow: hidden;
        padding-bottom: 10%;
    }

    .promoLink {
        text-decoration: none;
    }

    .boxTitle {
        color: white;
        font-size: 200%;
        text-align: center;
    }

    .boxTextContainer {
        padding: 1%;
    }

    .boxText {
        text-align: justify;
        padding: 0% 7% 0% 7%;
        color: white;
        font-size: 115%;
    }

    .bannerLinkB {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .bannerButtonB {
        background-color: white;
        color: rgb(0, 112, 176);
        font-size: 140%;
        border-radius: 50px;
        border: 1.5px solid white;
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 5%;
        padding-right: 5%;
        text-align: center;
        margin: 4%;
        text-decoration: none;
    }

    .bannerButtonB:hover {
        border: 1.5px solid rgb(255, 0, 68);
        background-color: rgb(255, 0, 68);
        color: white;
        cursor: pointer;
        transition: all 1s;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .promo {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .promoAContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }

    .promoCountries {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .titleA {
        color: rgb(0, 122, 176);
        ;
        font-size: 200%;
        text-align: center;
        margin: 3% 0% 2% 0%;
    }

    .promoLinkA {
        text-decoration: none;
        width: 100%;
    }

    .countriesContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .promoCountriesList {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
        height: 250px;
        background-image: url('../../../assets//img//promo/cuadro.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .countryFlagP {
        width: 6.5%;
        margin: 1%;
    }

    .promoProvList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        width: 100%;
        margin-bottom: 2%;
    }

    .provLogoA {
        width: auto;
        max-height: 50px;
    }

    .provLogoB {
        width: auto;
        max-height: 90px;
    }

    .provLogoC {
        width: auto;
        max-height: 30px;
    }

    .provLogoD {
        width: auto;
        max-height: 115px;
    }

    .provLogoE {
        width: auto;
        max-height: 80px;
    }

    .provLogoF {
        width: auto;
        max-height: 39px;
    }

    .provLogoG {
        width: auto;
        max-height: 120px;
    }

    .promoBContainer {
        background-color: rgb(0, 112, 176);
        width: 100%;
        overflow: hidden;
    }

    .promoLink {
        text-decoration: none;
    }

    .boxTitle {
        color: white;
        font-size: 200%;
        text-align: center;
    }

    .boxTextContainer {
        padding: 1%;
    }

    .boxText {
        text-align: justify;
        padding: 0% 7% 0% 7%;
        color: white;
        font-size: 115%;
    }

    .bannerLinkB {
        width: 100%;
        margin: auto;
    }

    .bannerButtonB {
        background-color: white;
        color: rgb(0, 112, 176);
        font-size: 140%;
        border-radius: 50px;
        border: 1.5px solid white;
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 5%;
        padding-right: 5%;
        text-align: center;
        margin: 3% 37% 8%;
    }

    .bannerButtonB:hover {
        border: 1.5px solid rgb(255, 0, 68);
        background-color: rgb(255, 0, 68);
        color: white;
        cursor: pointer;
        transition: all 1s;
    }
}