/*Desktop*/
@media (min-width: 1024px) {
    .error404 {
        width: 100%;
    }

    .errorContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 450px;
    }

    .errorText {
        font-size: 170%;
        text-align: center;
        margin: 7% 0% 0% 0%;
    }

    .errorLink {
        text-decoration: none;
        width: 100%;
        margin: auto;
        text-align: center;
    }

    .errorButton {
        background-color: rgb(0, 112, 176);
        color: white;
        font-size: 140%;
        border-radius: 50px;
        border: 1.5px solid rgb(0, 112, 176);
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 5%;
        padding-right: 5%;
        text-align: center;
        margin: 0% 0% 5% 0%;
    }

    .errorButton:hover {
        border: 1.5px solid rgb(255, 0, 68);
        background-color: rgb(255, 0, 68);
        ;
        color: white;
        cursor: pointer;
        transition: all 1s;
    }
}