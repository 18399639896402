/*Mobile*/
@media (max-width: 1024px) {
    .banner {
        position: relative;
    }

    .bannerImg {
        width: 100%;
        height: 500px;
    }

    .bannerText {
        position: absolute;
        bottom: 25%;
        text-align: center;
    }

    .bannerTitle {
        color: rgb(250, 250, 250);
        font-size: 270%;
    }

    .bannerSubtitle {
        color: rgb(250, 250, 250);
        font-size: 170%;
    }

    .bannerButton {
        background-color: rgb(0, 112, 176);
        color: white;
        font-size: 140%;
        border-radius: 50px;
        border: 1.5px solid rgb(0, 112, 176);
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 8%;
        text-align: center;
    }

    .bannerButton:hover {
        border: 1.5px solid rgb(255, 0, 68);
        background-color: rgb(255, 0, 68);
        color: white;
        cursor: pointer;
        transition: all 1s;
    }
}

/*Desktop*/
@media (min-width: 1024px) {
    .banner {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .bannerImg {
        width: 100%;
    }

    .bannerText {
        font-size: 115%;
    }

    .bannerTitle {
        color: white;
        font-size: 300%;
        margin: -35% 0% 0% -25%;
    }

    .bannerSubtitle {
        color: rgb(250, 250, 250);
        font-size: 200%;
        margin: 0% 0% 0% -30%;
    }

    .bannerButton {
        background-color: rgb(0, 112, 176);
        color: white;
        font-size: 140%;
        border-radius: 50px;
        border: 1.5px solid rgb(0, 112, 176);
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 5%;
        padding-right: 5%;
        text-align: center;
        margin: 10% 0% 10% 0%;
    }

    .bannerButton:hover {
        border: 1.5px solid rgb(255, 0, 68);
        background-color: rgb(255, 0, 68);
        color: white;
        cursor: pointer;
        transition: all 1s;
    }
}